.Logo-box {
    display: flex;
    align-items: center;
    justify-content: center;
}
.Logo-img {
    max-width: 200px;
    max-height: 200px;
    position: absolute;
    right: 100px;
    top: 100px;
}

.Logo-dot {
    max-width: 200px;
    max-height: 200px;
    position: absolute;
    right: 300px;
    top: 100px;
    z-index: 9;
}

@include media-breakpoint-down(xs) {
    .Logo-img {
        max-width: 100px;
        max-height: 100px;
        position: absolute;
        right: 50px;
        top: 50px;
    }

    .Logo-dot {
        max-width: 100px;
        max-height: 100px;
        position: absolute;
        right: 150px;
        top: 50px;
        z-index: 9;
    }
}

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
@font-face {
    font-family: "Comfortaa";
    src: local("Comfortaa"), url("../assets//fonts/Comfortaa.ttf") format("truetype");
}
@font-face {
    font-family: "Comfortaa Bold";
    src: local("Comfortaa Bold"), url("../assets//fonts/Comfortaa-bold.ttf") format("truetype");
}
